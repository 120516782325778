import { template as template_102e08626f5543d5983646b300d13f37 } from "@ember/template-compiler";
const WelcomeHeader = template_102e08626f5543d5983646b300d13f37(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
